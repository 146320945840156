import { faCheckCircle, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card } from "react-bootstrap";

function OnboardingCard({ status, ...otherProps }) {
  let iconToDisplay = otherProps.icon;
  let buttonText = otherProps.actionText;
  let color = otherProps.color || "#7D50C7";
  let style = {
    borderColor: "#E0E0E0",
    ...otherProps.style,
  };
  let onClick = () => otherProps.setActive(otherProps.actionValue);

  if (status === "completed") {
    iconToDisplay = faCheckCircle; // This assumes you have imported faCheck from Font Awesome
    buttonText = "";
    color = "#46C983";
  } else if (status === "incomplete") {
    style = { ...style, opacity: 0.5 }; // Or any other desired inactive style
    onClick = () => {};
  }


  // if status is next, add a small shadow to card
  let cardClasses = "p-5 py-4 h-100";
  if (status === "next") {
    cardClasses += "";
  }


  return (
    <Card
      style={{
        ...style,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      className={cardClasses}
    >
      <div
        className="card-body"
        style={{
          flex: "none",
        }}
      >
        <h5 className="fw-bold">
          <FontAwesomeIcon
            icon={iconToDisplay}
            size="2x"
            className="mb-3"
            style={{ color: color }}
          />
          <br />
          {otherProps.title}
        </h5>
        <p className="mt-2 mb-0">{otherProps.description}</p>
        {status === "completed" && (
          <Button
            variant="link" // This makes it look like a link
            className="text-black mt-2" // Custom styling
            style={{
              textDecoration: "none",
              border: "none",
              padding: 0,
              background: "none",
            }}
            onClick={onClick} // Trigger your edit function
          >
            <FontAwesomeIcon icon={faEdit} size="sm" className="me-1" />
            Edit
          </Button>
        )}
        {buttonText && (
          <Button
            onClick={onClick}
            style={{
              backgroundColor: color,
              borderColor: color,
              color: "#fff",
              fontFamily: "Oswald",
              fontWeight: "bold",
              padding: "5px 20px",
            }}
            variant="primary"
            className="rounded-lg mt-3 bg-gradient border-0"
          >
            {buttonText}
          </Button>
        )}
      </div>
    </Card>
  );
}

export default OnboardingCard;
