// ApplicationReview.js

import React, { useContext } from "react";
import {
  Card,
  // Form,
  Button,
  Container,
  Row,
  Col,
} from "react-bootstrap";
// import { MM_CRM_LOCATION_ID } from "../../../../../integrations/Highlevel/constants";
// import { API_BASE_URL } from "../../../../../utils/constants";
// import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faCheckCircle,
  faEdit,
  // faMusic,
} from "@fortawesome/free-solid-svg-icons";
// import { formatCurrency } from "../../../../FunnelAccelerator/utils";
// import { format, parse, set } from "date-fns";
// import { useQuery } from "../../../hooks/useQueryParams";
// import { toast } from "react-toastify";
// import { submitForm } from "../../../../../utils/submitForm";
import ContactContext from "../../../../../context/Contacts/ContactContext";

// function openInNewWindow(e, url) {
//   e.preventDefault();
//   window.open(url, "newwindow", "width=1280,height=720");
// }

const ApplicationReviewWidget = ({ testmode }) => {
  // ... component logic here ...

  // const [isLoading, setIsLoading] = useState(true);
  // const [isEditing, setIsEditing] = useState(false);

  // const [contactData, setContactData] = useState({
  //   first_name: "",
  //   last_name: "",
  // });

  const [contactData] = useContext(ContactContext);

  // const [formData, setFormData] = useState(null);

  // useEffect(() => {
  //   setIsLoading(true);
  //   console.log("contactData", contactData);
  //   console.log("fetchingCompleted", fetchingCompleted);

  //   if (contactData?.breakthrough?.application?.fields) {
  //     setFormData(contactData?.breakthrough?.application?.fields);
  //     // setContactData(contactData);
  //     setIsLoading(false);
  //   } else if (fetchingCompleted) {
  //     toast.error(`No contact_id found`);
  //   }
  // }, [contactData, setContactData, fetchingCompleted]);

  // Define form questions
  // const ARTIST_APPLICATION = [
  //   {
  //     key: "name",
  //     label: "What's your name?",
  //     inputType: "text",
  //   },
  //   {
  //     key: "email",
  //     label: "Email?",
  //     inputType: "email",
  //   },
  //   {
  //     key: "song_link",
  //     label: "Please share a link to stream one of your songs",
  //     inputType: "url",
  //   },
  //   {
  //     key: "artist_description",
  //     label: "Tell me briefly about your music career",
  //     inputType: "textarea",
  //     rows: 3,
  //   },
  //   {
  //     key: "fanbase_size",
  //     label: "What's the current size of your fanbase?",
  //     inputType: "radio",
  //     options: [
  //       "Under 1,000",
  //       "1,000 to 10,000",
  //       "10,000 to 100,000",
  //       "100,000 to 1,000,000",
  //       "Above 1,000,000",
  //     ],
  //   },
  //   {
  //     key: "monthly_revenue",
  //     label: "On average, how much are you making per month from music?",
  //     inputType: "radio",
  //     options: [
  //       "Under $100",
  //       "$100 to $1,000",
  //       "$1,000 to $10,000",
  //       "$10,000 to $100,000",
  //       "Above $100,000",
  //     ],
  //   },
  //   {
  //     key: "sustained_by_music",
  //     label:
  //       "Are you currently supporting yourself full-time with your music income?",
  //     inputType: "radio",
  //     options: ["Yes", "No"],
  //   },
  //   {
  //     key: "target_monthly_revenue",
  //     label:
  //       formData?.sustained_by_music === "Yes"
  //         ? "What's your target monthly revenue with your music?"
  //         : "How much would you need to make per month to fully replace your existing income?",
  //     inputType: "number",
  //     format: "currency",
  //   },
  //   {
  //     key: "annual_goals",
  //     label:
  //       "If we were having a discussion a year from today, and you were looking back on the past 12 months, what would have had to happen in both your life and career, for you to be excited about your progress?",
  //     inputType: "textarea",
  //     rows: 4,
  //   },
  //   {
  //     key: "roadblocks",
  //     label:
  //       "Be 100% honest - What do you think is stopping you from reaching your goals? (The more specific you are here the faster we'll be able to get you clarity during our call together)",
  //     inputType: "textarea",
  //     rows: 3,
  //   },
  //   {
  //     key: "coachable",
  //     label:
  //       "I'm looking for a few coaching clients to mentor personally with me and my team. If you're a good fit, would you be interested in working together?",
  //     inputType: "radio",
  //     options: ["Yes", "No"],
  //   },
  //   {
  //     key: "investable_time",
  //     label:
  //       "How many hours do you (or a team member) have available to focus on your music per day?",
  //     inputType: "radio",
  //     options: [
  //       "Less than 1 hour",
  //       "1 to 2 hours",
  //       "2 to 5 hours",
  //       "5 to 10 hours",
  //       "Above 10 hours",
  //     ],
  //   },
  //   {
  //     key: "belief",
  //     label: `If you bring in ${(
  //       formData?.target_monthly_revenue / 5
  //     ).toLocaleString()} fans per month who pay you $5, you'll earn ${formatCurrency(
  //       formData?.target_monthly_revenue
  //     )}/m in revenue. With an experienced mentor, do you believe you can achieve this or more?`,
  //     inputType: "radio",
  //     options: ["Yes", "No"],
  //   },
  //   {
  //     key: "resourceful",
  //     label:
  //       "The minimum investment for 1:1 coaching with Modern Musician is $1,000 per month. If we're a good fit, do you have the financial resources available to invest in your music career?",
  //     inputType: "radio",
  //     options: ["Yes", "No"],
  //   },
  //   {
  //     key: "investable_budget",
  //     label:
  //       "What is your maximum available budget per month to invest in growing your music career? (including recording, music videos, promotion, etc.)",
  //     inputType: "number",
  //     format: "currency",
  //     conditions: [
  //       {
  //         trigger: formData?.resourceful === "Yes",
  //         action: "skip",
  //       },
  //     ],
  //   },
  //   {
  //     key: "start_date",
  //     label: "If you are accepted, how soon can you get started?",
  //     inputType: "date",
  //   },
  // ];

  // handleSave function that submitForm()
  // const handleSave = async () => {
  //   await submitForm({
  //     location: { id: MM_CRM_LOCATION_ID },
  //     contactData: { id: contact_id },
  //     fields: {
  //       "breakthrough.application": formData,
  //     },
  //   });
  // };

  function openInNewWindow(e, url) {
    e.preventDefault();
    window.open(url, "newwindow");
  }

  if (testmode) {
    contactData.id = "PtWhqwioaHMDDx34zISx";
  }

  return (
    <>
      <Container fluid className="p-4 d-flex justify-content-center">
        <Row style={{ maxWidth: "800px" }}>
          <Col>
            <Card className="p-4">
              <Card.Body>
                <Row className="d-flex justify-content-center text-center">
                  <Col>
                    <h1>
                      {" "}
                      {contactData?.first_name} {contactData?.last_name}{" "}
                    </h1>
                    <h4 className="fw-light">
                      Artist Breakthrough Application
                    </h4>
                    <div>
                      <Button
                        variant="link"
                        className="text-dark"
                        onClick={(e) =>
                          openInNewWindow(
                            e,
                            `/app-review?crm_id=${contactData?.id}` // test id PtWhqwioaHMDDx34zISx
                          )
                        }
                      >
                        <FontAwesomeIcon className="me-2" icon={faEdit} />
                        Edit Application Details
                      </Button>
                    </div>
                    {/* <Button
                        variant="secondary"
                        className="my-0"
                        onClick={(e) => openInNewWindow(e, formData?.song_link)}
                      >
                        <FontAwesomeIcon className="me-2" icon={faMusic} />
                        Listen to Music
                      </Button> */}
                    {/* <div>
                        <Button
                          variant="secondary"
                          className="mt-2"
                          onClick={(e) =>
                            openInNewWindow(e, formData?.song_link)
                          }
                        >
                          <FontAwesomeIcon className="me-2" icon={faEdit} />
                          Edit Application
                        </Button>
                      </div> */}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default ApplicationReviewWidget;
