import * as React from "react";
import { useEffect, useState, useRef } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  Circle,
  useLoadScript,
  LoadScript,
} from "@react-google-maps/api";
// import axios from "axios";
import Papa from "papaparse";
import { useParams } from "react-router-dom";

// import styles.css
import "../fan-map-styles.css";
import { Container, Spinner } from "react-bootstrap";
import { addTagsToContacts, fetchContactMapData } from "../api";
import axios from "axios";
import {
  Modal,
  Button,
  Form,
  ToastContainer,
  Toast,
  Table,
} from "react-bootstrap";

const libraries = ["geometry"]; // Include the geometry library

const containerStyle = {
  width: "100vw",
  height: "80vh",
};

const initialCenter = {
  lat: 40.711946,
  lng: -96.73613,
};

// Define the major cities and their coordinates
const majorCities = [
  { name: "Fargo", lat: 46.8772, lng: -96.7898 },
  { name: "New York", lat: 40.7128, lng: -74.006 },
  { name: "Los Angeles", lat: 34.0522, lng: -118.2437 },
  { name: "Chicago", lat: 41.8781, lng: -87.6298 },
  { name: "Houston", lat: 29.7604, lng: -95.3698 },
  { name: "Phoenix", lat: 33.4484, lng: -112.074 },
  { name: "Philadelphia", lat: 39.9526, lng: -75.1652 },
  { name: "San Antonio", lat: 29.4241, lng: -98.4936 },
  { name: "San Diego", lat: 32.7157, lng: -117.1611 },
  { name: "Dallas", lat: 32.7767, lng: -96.797 },
  { name: "San Jose", lat: 37.3541, lng: -121.9552 },
  { name: "Austin", lat: 30.2672, lng: -97.7431 },
  { name: "Jacksonville", lat: 30.3322, lng: -81.6557 },
  { name: "Fort Worth", lat: 32.7555, lng: -97.3308 },
  { name: "Columbus", lat: 39.9612, lng: -82.9988 },
  { name: "San Francisco", lat: 37.7749, lng: -122.4194 },
  { name: "Indianapolis", lat: 39.7684, lng: -86.1581 },
  { name: "Charlotte", lat: 35.2271, lng: -80.8431 },
  { name: "Seattle", lat: 47.6062, lng: -122.3321 },
  { name: "Denver", lat: 39.7392, lng: -104.9903 },
  { name: "Washington, D.C.", lat: 38.8951, lng: -77.0369 },
  { name: "Boston", lat: 42.3601, lng: -71.0589 },
  { name: "El Paso", lat: 31.7619, lng: -106.485 },
  { name: "Nashville", lat: 36.1627, lng: -86.7816 },
  { name: "Detroit", lat: 42.3314, lng: -83.0458 },
  { name: "Oklahoma City", lat: 35.4634, lng: -97.5151 },
  { name: "Portland", lat: 45.5051, lng: -122.675 },
  { name: "Las Vegas", lat: 36.1699, lng: -115.1398 },
  { name: "Memphis", lat: 35.1495, lng: -90.049 },
  { name: "Louisville", lat: 38.2527, lng: -85.7585 },
  { name: "Baltimore", lat: 39.2904, lng: -76.6122 },
  { name: "Milwaukee", lat: 43.0389, lng: -87.9065 },
  { name: "Albuquerque", lat: 35.0844, lng: -106.6504 },
  { name: "Tucson", lat: 32.2226, lng: -110.9747 },
  { name: "Fresno", lat: 36.7468, lng: -119.7726 },
  { name: "Sacramento", lat: 38.5816, lng: -121.4944 },
  { name: "Mesa", lat: 33.4152, lng: -111.8315 },
  { name: "Atlanta", lat: 33.749, lng: -84.388 },
  { name: "Kansas City", lat: 39.0997, lng: -94.5786 },
  { name: "Long Beach", lat: 33.7701, lng: -118.1937 },
  { name: "Omaha", lat: 41.2565, lng: -95.9345 },
  { name: "Raleigh", lat: 35.7796, lng: -78.6382 },
  { name: "Colorado Springs", lat: 38.8339, lng: -104.8253 },
  { name: "Miami", lat: 25.7617, lng: -80.1918 },
  { name: "Oakland", lat: 37.8044, lng: -122.2711 },
  { name: "Minneapolis", lat: 44.9778, lng: -93.265 },
  { name: "Tulsa", lat: 36.154, lng: -95.9928 },
  { name: "Cleveland", lat: 41.4993, lng: -81.6944 },
  { name: "Wichita", lat: 37.6889, lng: -97.3361 },
  { name: "Arlington", lat: 32.7357, lng: -97.1081 },
  { name: "New Orleans", lat: 29.9511, lng: -90.0715 },
  { name: "Bakersfield", lat: 35.3733, lng: -119.0187 },
  { name: "Tampa", lat: 27.9506, lng: -82.4572 },
  { name: "Aurora", lat: 39.7294, lng: -104.8319 },
  { name: "Anaheim", lat: 33.8366, lng: -117.9143 },
  { name: "Santa Ana", lat: 33.7455, lng: -117.8677 },
  { name: "St. Louis", lat: 38.627, lng: -90.1994 },
  { name: "Riverside", lat: 33.9806, lng: -117.3755 },
  { name: "Corpus Christi", lat: 27.8006, lng: -97.3964 },
  { name: "Lexington", lat: 38.0406, lng: -84.5037 },
  { name: "Pittsburgh", lat: 40.4406, lng: -79.9959 },
  { name: "Anchorage", lat: 61.2181, lng: -149.9003 },
  { name: "Stockton", lat: 37.9577, lng: -121.2908 },
  { name: "Cincinnati", lat: 39.1031, lng: -84.512 },
  { name: "St. Paul", lat: 44.9537, lng: -93.09 },
  { name: "Toledo", lat: 41.6639, lng: -83.5552 },
  { name: "Greensboro", lat: 36.0726, lng: -79.791 },
  { name: "Newark", lat: 40.7357, lng: -74.1724 },
  { name: "Plano", lat: 33.0198, lng: -96.6989 },
  { name: "Henderson", lat: 36.0397, lng: -114.9819 },
  { name: "Lincoln", lat: 40.8136, lng: -96.7026 },
  { name: "Orlando", lat: 28.5383, lng: -81.3792 },
  { name: "Jersey City", lat: 40.7282, lng: -74.0776 },
  { name: "Chula Vista", lat: 32.6401, lng: -117.0842 },
  { name: "Buffalo", lat: 42.8864, lng: -78.8784 },
  { name: "Fort Wayne", lat: 41.0793, lng: -85.1394 },
  { name: "Chandler", lat: 33.3062, lng: -111.8413 },
  { name: "St. Petersburg", lat: 27.7676, lng: -82.6409 },
  { name: "Laredo", lat: 27.5306, lng: -99.4803 },
  { name: "Durham", lat: 35.994, lng: -78.8986 },
  { name: "Irvine", lat: 33.6846, lng: -117.8265 },
  { name: "Madison", lat: 43.0731, lng: -89.4012 },
  { name: "Norfolk", lat: 36.8508, lng: -76.2859 },
  { name: "Lubbock", lat: 33.5779, lng: -101.8552 },
  { name: "Gilbert", lat: 33.3528, lng: -111.789 },
  { name: "Winston-Salem", lat: 36.0999, lng: -80.2442 },
  { name: "Glendale", lat: 33.5387, lng: -112.1859 },
  { name: "Reno", lat: 39.5296, lng: -119.8138 },
  { name: "Hialeah", lat: 25.8576, lng: -80.2781 },
  { name: "Garland", lat: 32.9126, lng: -96.6389 },
  { name: "Chesapeake", lat: 36.7682, lng: -76.2875 },
  { name: "Irving", lat: 32.814, lng: -96.9489 },
  { name: "North Las Vegas", lat: 36.1989, lng: -115.1175 },
  { name: "Scottsdale", lat: 33.4942, lng: -111.9261 },
  { name: "Baton Rouge", lat: 30.4515, lng: -91.1871 },
  { name: "Fremont", lat: 37.5485, lng: -121.9886 },
  { name: "Richmond", lat: 37.5407, lng: -77.436 },
  { name: "Boise", lat: 43.615, lng: -116.2023 },
  { name: "San Bernardino", lat: 34.1083, lng: -117.2898 },
  { name: "Birmingham", lat: 33.5207, lng: -86.8025 },
  { name: "Spokane", lat: 47.6588, lng: -117.426 },
  { name: "Rochester", lat: 43.161, lng: -77.6109 },
  { name: "Des Moines", lat: 41.6005, lng: -93.6091 },
  { name: "Modesto", lat: 37.6391, lng: -120.9969 },
  { name: "Fayetteville", lat: 35.0526, lng: -78.8784 },
  { name: "Tacoma", lat: 47.2529, lng: -122.4443 },
  { name: "Oxnard", lat: 34.1975, lng: -119.177 },
  { name: "Toronto", lat: 43.65107, lng: -79.347015 },
  { name: "Vancouver", lat: 49.2827, lng: -123.1207 },
  { name: "Montreal", lat: 45.5017, lng: -73.5673 },
  { name: "Calgary", lat: 51.0447, lng: -114.0719 },
  { name: "Ottawa", lat: 45.4215, lng: -75.6981 },
  { name: "Edmonton", lat: 53.5444, lng: -113.4909 },
  { name: "Winnipeg", lat: 49.8951, lng: -97.1384 },
  { name: "Quebec City", lat: 46.8139, lng: -71.208 },
  { name: "Halifax", lat: 44.6488, lng: -63.5752 },
  // Add more major cities here
];

// Function to calculate the distance between two points using the Haversine formula
function calculateDistance(lat1, lon1, lat2, lon2) {
  //  const R = 6371; // Radius of the Earth in kilometers
  const R = 3958.8; // Radius of the Earth in miles
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
    Math.cos((lat2 * Math.PI) / 180) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
}

// Function to assign each marker to the nearest major city
function assignMarkersToMajorCities(markers) {
  markers.forEach((marker) => {
    let closestCity = null;
    let closestDistance = Infinity;

    majorCities.forEach((city) => {
      const distance = calculateDistance(
        marker.lat,
        marker.lng,
        city.lat,
        city.lng
      );

      if (distance < closestDistance) {
        closestCity = city;
        closestDistance = distance;
      }
    });

    if (closestCity) {
      if (!marker.nearestCity) {
        marker.nearestCity = closestCity;
      }
    }
  });
}

// Define a component to handle tag addition
const AddTagModal = ({
  show,
  handleClose,
  markersInRadius,
  addTagsToContacts,
  accountSlug,
}) => {
  const [tag, setTag] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isToastSuccess, setIsToastSuccess] = useState(true);
  const [showContactDetails, setShowContactDetails] = useState(false);
  // Toggle visibility of contact details
  const toggleContactDetails = () => {
    setShowContactDetails(!showContactDetails);
  };

  const handleAddTag = async () => {
    if (!tag) {
      alert("Please enter a tag.");
      return;
    }

    // Confirm the operation
    const confirmAdd = window.confirm(
      `Are you sure you want to add "${tag}" to all selected contacts?`
    );
    if (!confirmAdd) {
      handleClose();
      return;
    }

    // Prepare data for the API call
    const location = { id: accountSlug }; // Replace with actual location id
    const contacts = markersInRadius.map((marker) => ({ id: marker.id }));
    const tags = tag;

    try {
      // Execute the addTagsToContacts function
      await addTagsToContacts({ location, contacts, tags });

      // Display success toast notification
      setIsToastSuccess(true);
      setToastMessage("Tags successfully added to contacts!");
      setShowToast(true);
    } catch (error) {
      console.error("Error adding tags to contacts:", error);

      // Display error toast notification
      setIsToastSuccess(false);
      setToastMessage("Failed to add tags to contacts.");
      setShowToast(true);
    } finally {
      handleClose(); // Close the modal
    }
  };

  // Dynamic message depending on whether a tag name has been entered
  const tagMessage = tag
    ? `${markersInRadius.length} contacts will be tagged with "${tag}".`
    : `${markersInRadius.length} contacts will be tagged.`;

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Tag to Contacts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Tag</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter tag"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
              />
            </Form.Group>
            <p>{tagMessage}</p>
            {showContactDetails && (
              <Table striped bordered hover size="sm" className="mt-3">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {markersInRadius.map((marker, index) => (
                    <tr key={index}>
                      <td>{marker.name}</td>
                      <td>{marker.email}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div
            className="d-flex justify-content-between"
            style={{ width: "100%" }}
          >
            <Button variant="outline-info" onClick={toggleContactDetails}>
              {showContactDetails ? "Hide" : "Show"} Contact Details
            </Button>
            <div>
              <Button
                variant="secondary"
                onClick={handleClose}
                style={{ marginRight: "0.5rem" }}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={handleAddTag}>
                Add Tag
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={5000}
          autohide
          bg={isToastSuccess ? "success" : "danger"}
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

function FanMapComponent() {
  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBXFFPvlCuSK2fQHRpMPnPU4bJ9gZbgihE",
    libraries,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [map, setMap] = React.useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();
  const [markers, setMarkers] = useState([]);
  // const [zipCodeTally, setZipCodeTally] = useState({});
  const [cityTally, setCityTally] = useState({});
  // const [totalTally, setTotalTally] = useState(0);
  const [showModal, setShowModal] = useState(false);
  // const [markersInRadius, setMarkersInRadius] = useState([]); // Populate this state as appropriate for your app

  const [rawContactData, setRawContactData] = useState([]);


  //  const [sortedCityTally, setSortedCityTally] = useState({});

  const [zoom, setZoom] = useState(4);

  const [center, setCenter] = useState(initialCenter); // Define center state here
  //  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  // Inside your component function
  // const [radius, setRadius] = useState(10); // Initial radius value is 10 miles
  const [radiusInMiles, setRadiusInMiles] = useState(1); // Initial radius value in miles
  // const [circleCenter, setCircleCenter] = useState(center);

  const radiusInMeters = radiusInMiles * 1609.34;

  const [radiusMarker, setRadiusMarker] = useState(null);

  const { accountSlug } = useParams(); // This will get the accountSlug param from the URL

  // this is kind of neat for keeping the radius drag marker on the edge of teh circle
  // const getMarkerPosition = (center, radius) => {
  //   // Convert radius from meters to degrees
  //   const radiusInDegrees = radius / 111300;

  //   // Calculate new position for the marker
  //   const lat = center.lat;
  //   const lng = center.lng - radiusInDegrees;

  //   return { lat, lng };
  // };

  // const radiusMarkerPosition = getMarkerPosition(center, radiusInMeters);
  // end this is kind of neat

  // Add a state variable to keep track of the visible markers
  const [visibleMarkers, setVisibleMarkers] = useState([]);
  useEffect(() => {
    if (accountSlug) {
      setIsLoading(true);
      const fetchContactData = async (accountSlug, lastKey) => {
        const response = await fetchContactMapData(accountSlug, lastKey, 100);
        setRawContactData(response.contacts)
        if (response.lastKey) {
          await fetchContactData(accountSlug, response.lastKey)
        }
      }
      fetchContactData(accountSlug);
    }
  }, [accountSlug, setRawContactData]);
  // Fetch latitude and longitude based on postal code
  useEffect(() => {
    const formatRawContactData = async (input) => {
      const raw_data = input;
      if (!raw_data && raw_data?.length < 1) {
        return
      }
      const chunkSize = 15; // Adjust the chunk size as needed
      const totalChunks = Math.ceil(raw_data.length / chunkSize);
      // Initialize an array outside of the code block that processes the chunks to collect missing postal codes
      let missingPostalCodes = [];

      for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize;
        const end = (i + 1) * chunkSize;
        const chunk = raw_data.slice(start, end);

        const batchedPostalCodes = chunk
          .map((contact) => contact?.postal_code)
          .filter((postal_code) => postal_code);

        // Batch geocoding for postal codes
        const batchedCoordinates = await Promise.all(
          batchedPostalCodes.map((postal_code) =>
            getCoordinatesFromCSV(postal_code)
          )
        );

        const filteredMarkers = chunk
          .map((contact, index) => {
            const postal_code = contact?.postal_code;
            if (postal_code) {
              const coordinates = batchedCoordinates[index];
              if (coordinates) {
                setIsLoading(false);
                return {
                  lat: coordinates.lat,
                  lng: coordinates.lng,
                  name: contact.name,
                  zipCode: postal_code,
                  id: contact.id,
                  email: contact.email,
                };
              } else {
                // Instead of logging immediately, add the missing postal code to the array
                missingPostalCodes.push(contact);
                setIsLoading(false);
              }
            }
            return null;
          })
          .filter((marker) => marker !== null);

        // Update state or perform any other necessary operations
        setMarkers((prevMarkers) => [...prevMarkers, ...filteredMarkers]);
        assignMarkersToMajorCities(filteredMarkers);

        // Calculate city tally based on visible markers
        const newCityTally = filteredMarkers.reduce((tally, marker) => {
          const cityName = marker.nearestCity.name;
          tally[cityName] = (tally[cityName] || 0) + 1;
          return tally;
        }, {});

        setCityTally((prevCityTally) => ({
          ...prevCityTally,
          ...newCityTally,
        }));
      }

      // After processing the chunk, check if there are any missing postal codes and log them
      if (missingPostalCodes.length > 0) {
        console.error(
          "No results found for the following postal codes:",
          missingPostalCodes
        );
      }
    }
    if (rawContactData && rawContactData?.length > 0) {
      formatRawContactData(rawContactData);
      setRawContactData([]);
    }

  }, [rawContactData, setRawContactData]);

  // Function to get coordinates from CSV file
  const getCoordinatesFromCSV = async (postalCode) => {
    return new Promise((resolve, reject) => {
      // Assuming you have the CSV file path as 'path/to/your/file.csv'
      Papa.parse(
        "https://cdn.glitch.global/23541ac2-c08b-41ed-b50f-810b03213326/bare_us_zip_codes_to_longitude_and_latitude.csv?v=1700166664830",
        {
          download: true,
          header: true,
          fastMode: true, // Enable fast mode
          complete: (results) => {
            const entry = results.data.find(
              (row) => row?.postalCode === postalCode
            );
            if (entry) {
              resolve({
                lat: parseFloat(entry.lat),
                lng: parseFloat(entry.lng),
              });
            } else {
              resolve(null);
            }
          },
          error: (error) => {
            reject(error.message);
          },
        }
      );
    });
  };

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleMarkerClick = (id, lat, lng, address, name, email, ltv) => {
    map?.panTo({ lat, lng });
    setInfoWindowData({ id, address, name, email, ltv });
    setIsOpen(true);
  };

  // const handleRadiusChange = (event) => {
  //   const miles = parseFloat(event.target.value);
  //   setRadiusInMiles(miles);
  // };

  //  I don't think this is doing anything
  const handleMapBoundsChanged = () => {
    if (mapRef.current) {
      //     const bounds = mapRef.current.getBounds();
      //      if (bounds) {
      const newCenter = center;
      setCenter(newCenter);
      // setCircleCenter(center);
      //      }
    }
  };

  // END  I don't think this is doing anything

  const countMarkersInRadius = () => {
    return markers.reduce((count, marker) => {
      const markerPosition = new window.google.maps.LatLng(
        marker.lat,
        marker.lng
      );
      const circleCenterPosition = new window.google.maps.LatLng(
        center.lat,
        center.lng
      );
      const distance =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          markerPosition,
          circleCenterPosition
        );

      // Increment count if the distance is within the radius
      return count + (distance <= radiusInMiles * 1609.34 ? 1 : 0);
    }, 0);
  };

  // Call the displayRadiusMarkerDetails function with the filtered list
  // displayRadiusMarkerDetails(markersInRadius);

  // Calculate the fan count based on the visible markers
  const fanCount = visibleMarkers.length;

  // Group markers by zip code and calculate tally
  // useEffect(() => {
  //   const zipCodeTally = markers.reduce((tally, marker) => {
  //     const zipCode = marker.zipCode;
  //     tally[zipCode] = (tally[zipCode] || 0) + 1;
  //     return tally;
  //   }, {});
  //   // setZipCodeTally(zipCodeTally);

  //   // Calculate total fan count
  //   const totalTally = Object.values(zipCodeTally).reduce(
  //     (total, count) => total + count,
  //     0
  //   );
  //   setTotalTally(totalTally);
  // }, [markers]);

  // useEffect hook for city tally when visible markers change
  useEffect(() => {
    const newCityTally = visibleMarkers.reduce((tally, marker) => {
      const cityName = marker.nearestCity.name;
      tally[cityName] = (tally[cityName] || 0) + 1;
      return tally;
    }, {});
    setCityTally(newCityTally);
  }, [visibleMarkers]);

  // Handle the "idle" event of the map
  useEffect(() => {
    if (map) {
      const updateVisibleMarkers = () => {
        const bounds = map.getBounds();
        const filteredMarkers = markers.filter(
          (marker) =>
            bounds && bounds.contains({ lat: marker.lat, lng: marker.lng })
        );
        setVisibleMarkers(filteredMarkers);
      };

      // Listen for the "idle" event and update visible markers
      map.addListener("idle", updateVisibleMarkers);
      // Initial update
      updateVisibleMarkers();
    }
  }, [map, markers]);

  // Calculate the sortedCityTally based on visible markers
  // const newSortedCityTally = visibleMarkers.reduce((tally, marker) => {
  //   const cityName = marker.nearestCity.name;
  //   tally[cityName] = (tally[cityName] || 0) + 1;
  //   return tally;
  // }, {});
  //setSortedCityTally(newSortedCityTally);

  // Calculate the tally by zip code based on visible markers
  const visibleZipCodeTally = visibleMarkers.reduce((tally, marker) => {
    const zipCode = marker.zipCode;
    tally[zipCode] = (tally[zipCode] || 0) + 1;
    return tally;
  }, {});
  // Create an HTML list of tallies by zip code for visible markers
  const tallyList = Object.entries(visibleZipCodeTally);

  // Use useEffect to add or update the control widget when fanCount changes
  useEffect(() => {
    // Function to create and add the control widget
    // Function to add or update the control widget
    const addCustomControl = (handleSearch) => {
      if (map) {
        // Clear existing controls to remove previous widgets
        map.controls[window.google.maps.ControlPosition.LEFT_TOP].clear();

        const controlDiv = document.createElement("div");
        controlDiv.classList.add("custom-control"); // Add a class to controlDiv
        const customControl = () => {
          controlDiv.style.backgroundColor = "white";
          controlDiv.style.border = "1px solid #ccc";
          controlDiv.style.padding = "10px";
          controlDiv.style.textAlign = "center";
          controlDiv.style.fontSize = "10px";
          controlDiv.style.margin = "0px 10px";

          const handleSearch = async (location) => {
            try {
              const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=AIzaSyClJW3qudaMCYflwX4Z4_SC8GbRGmV0_6E`
              );

              if (response.data.results && response.data.results.length > 0) {
                const locationData = response.data.results[0].geometry.location;
                setCenter({ lat: locationData.lat, lng: locationData.lng });
                setRadiusInMiles(50);
                setZoom(7);
                setRadiusMarker({
                  position: { lat: locationData.lat, lng: locationData.lng },
                });
              } else {
                console.error(`No results found for location: ${location}`);
              }
            } catch (error) {
              console.error(`Error geocoding location: ${location}`, error);
            }
          };
          // const handleSubmit = (e) => {
          //   e.preventDefault();
          //   setCenter(initialCenter); // Reset the map's center when submitting the form
          //   handleSearch(location);
          // };

          // Create a search input
          const searchInput = document.createElement("input");
          searchInput.type = "text";
          searchInput.placeholder = "Enter city or zip code";
          searchInput.style.marginBottom = "10px"; // Adjust styling as needed

          // Create a search button
          const searchButton = document.createElement("button");
          searchButton.textContent = "Center Map";
          searchButton.style.marginLeft = "5px"; // Adjust styling as needed

          // Handle search when the button is clicked
          searchButton.addEventListener("click", () => {
            const location = searchInput.value;
            handleSearch(location);
          });

          // Handle search when the "Enter" key is pressed in the input field
          searchInput.addEventListener("keydown", (event) => {
            if (event.key === "Enter") {
              const location = searchInput.value;
              handleSearch(location);
            }
          });

           // Create a radius reset button
          const resetRadiusButton = document.createElement("button");
          resetRadiusButton.textContent = "Reset Radius";
          resetRadiusButton.style.marginLeft = "5px"; // Adjust styling as needed

          // Handle radiusreset when the button is clicked
          resetRadiusButton.addEventListener("click", () => {
//            const handleRadiusReset = () => {
              setRadiusInMiles(0);
              //      setRadiusMarker(null);
              //         setRadiusMarker({ position: center });
              setRadiusMarker((prevState) => ({ ...prevState, position: center }));
  //          };
          });

          // const getLocationDataFromCSV = async (location) => {
          //   return new Promise((resolve, reject) => {
          //     // Assuming you have the CSV file path as 'path/to/your/file.csv'
          //     Papa.parse(
          //       "https://cdn.glitch.global/23541ac2-c08b-41ed-b50f-810b03213326/basic_us_city_zip_codes_to_longitude_and_latitude.csv?v=1703796482096",
          //       {
          //         download: true,
          //         header: true,
          //         fastMode: true, // Enable fast mode
          //         complete: (results) => {
          //           // Check if the input is a zip code
          //           const isZipCode = /^\d{5}$/.test(location);

          //           //        const entry = results.data.find((row) => row.postalCode === location);
          //           const entry = results.data.find((row) => {
          //             if (isZipCode) {
          //               return row.postalCode === location;
          //             } else {
          //               // Assuming you have a column 'city' in your CSV file
          //               return row.city.toLowerCase() === location.toLowerCase();
          //             }
          //           });

          //           if (entry) {
          //             resolve({
          //               lat: parseFloat(entry.lat),
          //               lng: parseFloat(entry.lng),
          //             });
          //           } else {
          //             resolve(null);
          //           }
          //         },
          //         error: (error) => {
          //           reject(error.message);
          //         },
          //       }
          //     );
          //   });
          // };

          // const handleSubmit = (e) => {
          //   e.preventDefault();
          //   setCenter(initialCenter); // Reset the map's center when submitting the form
          //   handleSearch(location);
          // };

          // // Create a search input
          // const searchInput = document.createElement("input");
          // searchInput.type = "text";
          // searchInput.placeholder = "Enter city or zip code";
          // searchInput.style.marginBottom = "10px"; // Adjust styling as needed

          // // Create a search button
          // const searchButton = document.createElement("button");
          // searchButton.textContent = "Center Map";
          // searchButton.style.marginLeft = "5px"; // Adjust styling as needed

          // Handle search when the button is clicked
          searchButton.addEventListener("click", () => {
            const location = searchInput.value;
            handleSearch(location);
          });

          // Handle search when the "Enter" key is pressed in the input field
          searchInput.addEventListener("keydown", (event) => {
            if (event.key === "Enter") {
              const location = searchInput.value;
              handleSearch(location);
            }
          });

          // Create an unordered list (ul) for tallyList
          // const ul = document.createElement("ul");
          // //      ul.style.listStyleType = "square"; // Remove bullet points
          // //      ul.style.margin = "0";
          // //      ul.style.padding = "0px 5px";

          // // Loop through tallyList and create list items (li)
          // tallyList.forEach(([zipCode, count]) => {
          //   const li = document.createElement("li");
          //   li.textContent = `${zipCode}: ${count}`;
          //   ul.appendChild(li);
          // });

          // Display "Carpe Diem Joe Eddie" and fan count
          controlDiv.innerHTML = `<img src="https://assets.streetteam.me/icons/streetteam-logo.png" width="25px" /><br><h3 style="margin:0px;">Fan Map</h3><br>Total Fans on Map: <strong>${fanCount}</strong> <span class="high-fives">&#x1F64C;</span> <br><br>`;
          // Display "Carpe Diem Joe Eddie" and fan count

          controlDiv.appendChild(searchInput); // Add the search input

          const br = document.createElement("br");
          controlDiv.appendChild(br);
          controlDiv.appendChild(searchButton); // Add the search button
          controlDiv.appendChild(resetRadiusButton); // Add the radius reset button

          // const p = document.createElement("p");
          // p.innerHTML = "Zip Code: # of Fans";

          // controlDiv.appendChild(p);

          // Append the unordered list to the controlDiv
          // controlDiv.appendChild(ul);

          return controlDiv;
        };

        const controlUI = customControl();
        map.controls[window.google.maps.ControlPosition.LEFT_TOP].push(
          controlUI
        );
      }
    };

    addCustomControl();
  }, [map, fanCount, tallyList]);

  const [sortOption2, setSortOption2] = useState("count"); // or "count"

  const handleSortChange2 = (e) => {
    setSortOption2(e.target.value);
  };

  // Sorting function
  // Sorting function
  const sortedCityTally = Object.entries(cityTally).sort((a, b) => {
    if (sortOption2 === "cityName") {
      return a[0].localeCompare(b[0]);
    } else {
      return b[1] - a[1];
    }
  });

  // const [sortOption, setSortOption] = useState("nearestCity");

  const sortOption = "nearestCity";

  // const handleSortChange = (e) => {
  //   setSortOption(e.target.value);
  // };

  // Function to calculate the distance between two points using the Haversine formula
  // function calculateDistance(lat1, lon1, lat2, lon2) {
  //   //  const R = 6371; // Radius of the Earth in kilometers
  //   const R = 3958.8; // Radius of the Earth in miles
  //   const dLat = ((lat2 - lat1) * Math.PI) / 180;
  //   const dLon = ((lon2 - lon1) * Math.PI) / 180;
  //   const a =
  //     Math.sin(dLat / 2) * Math.sin(dLat / 2) +
  //     Math.cos((lat1 * Math.PI) / 180) *
  //       Math.cos((lat2 * Math.PI) / 180) *
  //       Math.sin(dLon / 2) *
  //       Math.sin(dLon / 2);
  //   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  //   const distance = R * c;
  //   return distance;
  // }

  // Sort the visibleZipCodeTally data based on the current sort option
  // let sortedZipCodeTally = Object.entries(visibleZipCodeTally);

  if (sortOption === "numerical") {
    // sortedZipCodeTally = sortedZipCodeTally.sort((a, b) =>
    //   a[0].localeCompare(b[0], undefined, { numeric: true })
    // );
  } else if (sortOption === "zipWithGreatestTally") {
    // sortedZipCodeTally = sortedZipCodeTally.sort((a, b) => b[1] - a[1]);
  } else if (sortOption === "distanceFromCenter") {
    // sortedZipCodeTally = sortedZipCodeTally.sort((a, b) => {
    //   const markerA = markers.find((marker) => marker.zipCode === a[0]);
    //   const markerB = markers.find((marker) => marker.zipCode === b[0]);

    //   if (markerA && markerB) {
    //     const distanceA = calculateDistance(
    //       center.lat,
    //       center.lng,
    //       markerA.lat,
    //       markerA.lng
    //     );
    //     const distanceB = calculateDistance(
    //       center.lat,
    //       center.lng,
    //       markerB.lat,
    //       markerB.lng
    //     );
    //     return distanceA - distanceB;
    //   }

    //   return 0;
    // });
  } else if (sortOption === "nearestCity") {
    // sortedZipCodeTally = sortedZipCodeTally.sort((a, b) => {
    //   const markerA = markers.find((marker) => marker.zipCode === a[0]);
    //   const markerB = markers.find((marker) => marker.zipCode === b[0]);

    //   if (markerA && markerB) {
    //     const cityA = markerA.nearestCity ? markerA.nearestCity.name : "";
    //     const cityB = markerB.nearestCity ? markerB.nearestCity.name : "";
    //     return cityA.localeCompare(cityB);
    //   }

    //   return 0;
    // });
  }

  //    const mapRef = React.useRef(null);
  const mapRef = useRef(null);
  const handleMapIdle = () => {
    if (mapRef.current) {
      const newCenter = mapRef.current.getCenter().toJSON();
      setCenter(newCenter);
    }
  };

  const handleRadiusMarkerDrag = (event) => {
    const newRadius = calculateNewRadius(center, event.latLng.toJSON());
    setRadiusInMiles(newRadius);
  };

  const calculateNewRadius = (center, newLatLng) => {
    const centerLatLng = new window.google.maps.LatLng(center.lat, center.lng);
    const newLatLngObj = new window.google.maps.LatLng(
      newLatLng.lat,
      newLatLng.lng
    );

    // Calculate distance between the center and the newLatLng
    const distance =
      window.google.maps.geometry.spherical.computeDistanceBetween(
        centerLatLng,
        newLatLngObj
      );

    // Convert distance to miles
    const newRadius = distance / 1609.34;
    return newRadius;
  };

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.addListener("idle", handleMapIdle);
    }
  }, []);

  const isMarkerInRadius = (marker) => {
    const markerPosition = new window.google.maps.LatLng(
      marker.lat,
      marker.lng
    );
    const circleCenterPosition = new window.google.maps.LatLng(
      center.lat,
      center.lng
    );
    const distance =
      window.google.maps.geometry.spherical.computeDistanceBetween(
        markerPosition,
        circleCenterPosition
      );

    // Check if the distance is within the radius
    return distance <= radiusInMiles * 1609.34; // Convert radius to meters
  };

  // Filter markers within the radius
  const markersInRadius = markers.filter(isMarkerInRadius);

  // custom icon for the radius dragger
  const customIcon = {
    path: "M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z",
    fillColor: "blue",
    fillOpacity: 1,
    scale: 1,
    strokeColor: "white",
    strokeWeight: 2,
    anchor: new window.google.maps.Point(10, 10),
  };

  // const [showCircle, setShowCircle] = useState(true);
  // // const handleRadiusClick = () => {
  // //   // Add your additional functions here
  // //   console.log("Button clicked!");
  // //   // Example: setRadiusMarker or any other function
  // //   // setRadiusMarker(/* your parameters */);
  // //   //      setRadiusMarker;
  // //   setShowCircle(!showCircle);
  // //   //     handleRadiusChange(markersInRadius);
  // // };
  // // const handleRadiusReset = () => {
  // //   setRadiusInMiles(0);
  // //   //      setRadiusMarker(null);
  // //   //         setRadiusMarker({ position: center });
  // //   setRadiusMarker((prevState) => ({ ...prevState, position: center }));
  // // };

  const showCircle = true;


  const [infoWindowOpen, setInfoWindowOpen] = useState(false);

  // const handleRadiusMarkerClick = () => {
  //   // Toggle the state to open/close the InfoWindow
  //   setInfoWindowOpen(!infoWindowOpen);
  // };

  useEffect(() => {
    const googleMap = mapRef.current;

    if (googleMap) {
      // Add a right-click event listener to the map
      const rightClickListener = map.addListener("rightclick", (e) => {
        setCenter({ lat: e.latLng.lat(), lng: e.latLng.lng() });
        setRadiusMarker({
          position: { lat: e.latLng.lat(), lng: e.latLng.lng() },
        });
        setRadiusInMiles(50);
        setZoom(7);
      });

      // Clean up the event listener when the component is unmounted
      return () => {
        window.google.maps.event.removeListener(rightClickListener);
      };
    }
  }, [map]);

  return isLoaded ? (
    !isLoading ? (
      <div className="el-google-map">
        <GoogleMap
          ref={(map) => (mapRef.current = map)}
          mapContainerStyle={containerStyle}
          //  options={{ mapTypeId: 'hybrid' }} // Set mapTypeId to 'satellite'
          options={{ scaleControl: true }}
          center={center}
          zoom={zoom}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onClick={() => setIsOpen(false)}
          onBoundsChanged={handleMapBoundsChanged}
        >
          {markers.map((marker, ind) => (
            <Marker
              key={ind}
              position={{ lat: marker.lat, lng: marker.lng }}
              // Set icon only for markers within the radius
              icon={
                isMarkerInRadius(marker)
                  ? {
//                    url: "https://firebasestorage.googleapis.com/v0/b/hlpt-support-widget.appspot.com/o/covers%2F12345%2F1688592243760_Slice%201.ico?alt=media&token=19450d40-279d-492b-8c92-431de489ec0d",
                    url: "https://joeeddie.com/wp-content/uploads/2016/03/cropped-favicon.png",
                    //  url: "{contact.logo_white}";
                    // url: {location.logo_white}
                    scaledSize: new window.google.maps.Size(30, 30),
                  }
                  : undefined
              }
              //                        icon={{
              //              url: isMarkerInRadius(marker) ? 'https://joeeddie.com/wp-content/uploads/2016/03/cropped-favicon.png' : 'url-to-default-marker.png',
              //              scaledSize: new window.google.maps.Size(30, 30),
              //            }}

              //            icon={{
              //    url: 'https://joeeddie.com/wp-content/uploads/2016/03/cropped-favicon.png', // Replace with the actual URL of your custom marker icon
              //    scaledSize: new window.google.maps.Size(30, 30), // Adjust the size as needed
              //   origin: new window.google.maps.Point(0, 0), // You might need to adjust this
              //   anchor: new window.google.maps.Point(20, 40), // You might need to adjust this
              //            }}
              onClick={() => {
                handleMarkerClick(
                  ind,
                  marker.lat,
                  marker.lng,
                  marker.address,
                  marker.name,
                  marker.email,
                  marker.ltv
                );
              }}
            >
              {isOpen && infoWindowData?.id === ind && (
                <InfoWindow
                  onCloseClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <>
                    <h3>{infoWindowData.name}</h3>
                    <p>{infoWindowData.address}</p>
                    <p>{infoWindowData.email}</p>
                  </>
                </InfoWindow>
              )}
            </Marker>
          ))}

          {showCircle && (
            /* Render circle with specified radius */
            <Circle
              center={center}
              radius={radiusInMeters}
              options={{
                strokeColor: "#FF9900", // Border color (hex color code)
                strokeWeight: 4, // Border thickness in pixels
                fillColor: "#00CCAA", // Fill color inside the circle (hex color code)
                fillOpacity: 0.4, // Opacity of the fill color (0 to 1)
              }}
            />
          )}

          {/* Render draggable marker at the edge of the circle */}
          {showCircle && radiusMarker && (
            <div className="radius-marker-styling">
              <Marker
                icon={customIcon}
                position={radiusMarker.position}
                //            position={radiusMarkerPosition}
                draggable={true}
                onDrag={(e) => handleRadiusMarkerDrag(e)}
                // why would this be in there          onClick={() => setRadiusMarker(null)}
                //              onClick={() => {
                //    setRadiusMarker(null); // First action
                //    handleRadiusMarkerClick();// Additional actions here
                //    setInfoWindowOpen(true); // For example, open the InfoWindow
                //  }}
                options={{
                  cursor: "sw-resize",
                }}
                label={{
                  text: `\u2300 Radius: ${radiusInMiles.toFixed(2)} miles`, // Round to two decimal places
                  className: "radius-marker-styling",
                }}
              //         />
              >
                {/* Conditionally render the InfoWindow based on state */}
                {/* I'm not using this but it could be useful! */}
                {infoWindowOpen && (
                  <InfoWindow
                    onCloseClick={() => setInfoWindowOpen(false)}
                    position={radiusMarker.position} // Set the position for the InfoWindow
                  >
                    <div>
                      {/* Add content for the InfoWindow */}
                      <h3>InfoWindow Content</h3>
                      <p>Additional details if needed</p>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
              {/* Conditionally render the InfoWindow based on state */}
              {/* I'm not using this but it could be useful! */}
            </div>
          )}

          {/* Render tally of markers within the radius */}
          <>
            <div
              className="map-radius-tally"
              style={{
                position: "absolute",
                bottom: "20px",
                left: "45%",
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "5px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              &#x1F3AF; Fans within radius: {countMarkersInRadius()}{" "}
              &nbsp;&nbsp;
              <Button onClick={handleOpenModal}>Add Tag</Button>
            </div>

            <AddTagModal
              show={showModal}
              handleClose={handleCloseModal}
              markersInRadius={markersInRadius}
              addTagsToContacts={addTagsToContacts}
              accountSlug={accountSlug}
            />
          </>
        </GoogleMap>

        <div className="tally-count-div">
          {/* <div className="tally-count-div-inner">
            <h3>Choose a location to center the map: </h3>
          </div> */}

          {/* <div className="search-controls">
            <div className="search-container">
            </div>

            <div className="radius-input-container">
              <label>&#x2300; Radius (in miles):</label>
              <input
                type="number"
                value={radiusInMiles}
                onChange={handleRadiusChange}
              />
            </div>

            <div className="radius-control-buttons">
              <button
                onClick={() => {
                  setRadiusMarker({ position: radiusMarkerPosition });
                }}
              >
                Draw Radius
              </button>
              <button
                onClick={() => {
                  setRadiusMarker(null);
                }}
              >
                Hide Draw Tool
              </button>
              <button onClick={handleRadiusClick}>
                {showCircle ? "Hide Radius" : "Show Radius"}
              </button>
              <button onClick={handleRadiusReset}>Reset Radius</button>
            </div>

            <div className="radius-tally">
              <div className="targeted">
                &#x1F3AF; Fans within radius: {countMarkersInRadius()}{" "}
                &nbsp;&nbsp;{" "}
                <button
                  onClick={() => displayRadiusMarkerDetails(markersInRadius)}
                >
                  Add Tag
                </button>{" "}
              </div>
            </div>
          </div> */}

          <div className="tally-count-div-inner">
            <h3>
              Total Fans on Map: <span className="fancount">{fanCount}</span>{" "}
              <span className="high-fives">&#x1F64C;</span> Fans within radius:{" "}
              <span className="radiuscount">{countMarkersInRadius()}</span>{" "}
              <span className="high-fives">&#x1F3AF;</span> &nbsp;&nbsp;{" "}
            </h3>

            {/* <div>
              <button
                onClick={() => displayRadiusMarkerDetailsDiv(markersInRadius)}
              >
                Those In Radius
              </button>
              <button onClick={() => addToWorkflow(visibleMarkers)}>
                Throw to Console
              </button>
              <button onClick={() => displayMarkerDetails(visibleMarkers)}>
                Add to Workflow
              </button>
              <button onClick={() => displayMarkerDetails(visibleMarkers)}>
                Send SMS
              </button>
              <button onClick={() => displayMarkerDetails(visibleMarkers)}>
                Send Email
              </button>
              <button onClick={() => displayMarkerDetails(visibleMarkers)}>
                Add Tag
              </button>
              <button onClick={() => displayMarkerDetails(visibleMarkers)}>
                Remove Tag
              </button>
              <button onClick={() => displayMarkerDetails(visibleMarkers)}>
                Delete Contact
              </button>
              <button onClick={() => displayMarkerDetails(visibleMarkers)}>
                Send Review Request
              </button>
              <button onClick={() => displayMarkerDetails(visibleMarkers)}>
                Export Contacts
              </button>
            </div> */}
            <div className="visible-markers-results"></div>

            <div className="citytable">
              <h4 className="h4nopad">
                A List of Cities with # of Fans in each City:
              </h4>
              <div className="tablesort">
                <div className="sort-container">
                  <label htmlFor="sortOption2">Sort by:</label>
                  <select
                    id="sortOption2"
                    value={sortOption2}
                    onChange={handleSortChange2}
                    className="custom-select" /* Apply a custom-select class */
                  >
                    <option value="cityName">City Name</option>
                    <option value="zipWithGreatestTally">Most Fans</option>
                  </select>
                </div>
              </div>
              <div className="clear"></div>
              <div className="tabletop">
                <div
                  className="tablezip"
                  onClick={() =>
                    handleSortChange2({ target: { value: "cityName" } })
                  }
                >
                  <span className="high-fives">&#x1F30E;</span> Nearest Metro
                </div>
                <div
                  className="tablecount"
                  onClick={() =>
                    handleSortChange2({
                      target: { value: "zipWithGreatestTally" },
                    })
                  }
                >
                  # of Fans
                </div>

                <div className="clear"></div>
              </div>

              <div className="citytally">
                <ul>
                  {sortedCityTally.map(([city, count]) => {
                    //    const marker2 = markers.find((marker) => marker.city === city);
                    //    const distance2 = marker2
                    //      ? calculateDistance(center.lat, center.lng, marker2.lat, marker2.lng)
                    //      : 0;
                    return (
                      <li key={city}>
                        <div className="tablezip">{city}</div>
                        <div className="tablecount">{count}</div>

                        <div className="clear"></div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="spacer"></div>
            {/* <div>
              <h4 className="h4nopad">
                A List of Zip Codes with # of Fans in each Zip Code:
              </h4>
              <div className="tablesort">
                <div className="sort-container">
                  <label htmlFor="sortOption">Sort by:</label>
                  <select
                    id="sortOption"
                    value={sortOption}
                    onChange={handleSortChange}
                    className="custom-select"
                  >
                    <option value="numerical">
                      Numerical Order (Zip Code)
                    </option>
                    <option value="zipWithGreatestTally">Most Fans</option>
                    <option value="distanceFromCenter">
                      Distance from Center
                    </option>
                    <option value="nearestCity">Nearest Major City</option>
                  </select>
                </div>
              </div>
              <div className="clear"></div>
            </div> */}

            {/* <div className="tabletop">
              <div
                className="tablezip"
                onClick={() =>
                  handleSortChange({ target: { value: "numerical" } })
                }
              >
                <span className="high-fives">&#x1F64D;</span> Zip Code
              </div>
              <div
                className="tablecount"
                onClick={() =>
                  handleSortChange({
                    target: { value: "zipWithGreatestTally" },
                  })
                }
              >
                # of Fans
              </div>
              <div
                className="tablenearest"
                onClick={() =>
                  handleSortChange({ target: { value: "nearestCity" } })
                }
              >
                Nearest Major City
              </div>
              <div
                className="tabledistance"
                onClick={() =>
                  handleSortChange({ target: { value: "distanceFromCenter" } })
                }
              >
                Distance from Center
              </div>

              <div className="clear"></div>
            </div> */}

            {/* <ul>
              {sortedZipCodeTally.map(([zipCode, count]) => {
                const marker = markers.find(
                  (marker) => marker.zipCode === zipCode
                );
                const distance = marker
                  ? calculateDistance(
                      center.lat,
                      center.lng,
                      marker.lat,
                      marker.lng
                    )
                  : 0;
                return (
                  <li key={zipCode}>
                    <div className="tablezip">{zipCode}</div>
                    <div className="tablecount">{count}</div>
                    <div className="tablenearest">
                      {marker.nearestCity ? marker.nearestCity.name : "N/A"}
                    </div>
                    <div className="tabledistance">
                      {distance.toFixed(2)} miles
                    </div>
                    <div className="clear"></div>
                  </li>
                );
              })}
            </ul> */}
          </div>
        </div>
      </div>
    ) : (
      <Container className="vh-100 vw-100 d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status"></Spinner>
      </Container>
    )
  ) : (
    <></>
  );
}

// function displayMarkerDetails(visibleMarkers) {
//   if (visibleMarkers.length > 0) {
//     // Create a string that contains the details of visible markers
//     const markerDetails = visibleMarkers.map((marker) => {
//       return `Name: ${marker.name}, ID: ${marker.id}`;
//     });

//     // Join the details with line breaks
//     const detailsText = markerDetails.join("\n");

//     // Display the details using an alert
//     alert("Visible Marker Details:\n\n" + detailsText);
//   } else {
//     // Handle the case when there are no visible markers
//     alert("No visible markers to display.");
//   }
// }

// function displayRadiusMarkerDetails(markersInRadius) {
//   // replace with functionality to add tag to contacts

//   if (markersInRadius.length > 0) {
//     // Create a string that contains the details of markers within the radius
//     const markerDetails = markersInRadius.map((marker) => {
//       return `Name: ${marker.name}, ID: ${marker.id}`;
//     });

//     // Join the details with line breaks
//     const detailsText = markerDetails.join("\n");

//     // map over the markersInRadius and create an array of contact ids
//     const contactIds = markersInRadius.map((marker) => marker.id);

//     let crm_location = {
//       id: "IeTzshedlOc5MeNZrAPU",
//     };

//     let response = addTagToContacts(contactIds, crm_location);
//     // console.log("response", response);

//     // Display the details using an alert
//     alert("Fans within radius details:\n\n" + detailsText);
//   } else {
//     // Handle the case when there are no markers within the radius
//     alert("No fans within the radius to display.");
//   }
// }

// add tags to contacts by an array of contact.ids
// async function addTagToContacts(contactIds, location) {
//   // Implement logic to add the tag to the contact
//   console.log("Adding tag to contacts:", contactIds, location);
//   try {
//     // await axios.post(`example.com/api/${location.id}/add-tag`, contactIds);
//     return "Tags added successfully";
//   } catch (error) {}
// }
// Implement logic to add the tag to the contact

// function displayMarkerDetailsDiv(visibleMarkers) {
//   // Get the div element with the class "visible-markers-results"
//   const resultsDiv = document.querySelector(".visible-markers-results");

//   if (visibleMarkers.length > 0) {
//     // Create an HTML list to display the marker details
//     const ul = document.createElement("ul");

//     // Iterate through visible markers and create list items
//     visibleMarkers.forEach((marker) => {
//       const li = document.createElement("li");
//       li.textContent = `Name: ${marker.name}, ID: ${marker.id}`;
//       ul.appendChild(li);
//     });

//     // Clear any previous content and append the list to the div
//     resultsDiv.innerHTML = "";
//     resultsDiv.appendChild(ul);
//   } else {
//     // If no visible markers, display a message
//     resultsDiv.textContent = "No visible markers to display.";
//   }
// }

// function displayRadiusMarkerDetailsDiv(markersInRadius) {
//   // Get the div element with the class "visible-markers-results"
//   const resultsDiv = document.querySelector(".visible-markers-results");

//   if (markersInRadius.length > 0) {
//     // Create an HTML list to display the marker details
//     const ul = document.createElement("ul");

//     // Iterate through visible markers and create list items
//     markersInRadius.forEach((marker) => {
//       const li = document.createElement("li");
//       li.textContent = `Name: ${marker.name}, ID: ${marker.id}`;
//       ul.appendChild(li);
//     });

//     // Clear any previous content and append the list to the div
//     resultsDiv.innerHTML = "";
//     resultsDiv.appendChild(ul);
//   } else {
//     // If no visible markers, display a message
//     resultsDiv.textContent = "No visible markers to display.";
//   }
// }

// function addToWorkflow(visibleMarkers) {
//   // Implement logic to add the visible markers to the workflow
//   // You can access the marker properties (e.g., zipCode) from the visibleMarkers array
//   console.log("Adding to workflow:", visibleMarkers);
// }

// function MapSearch({ setCenter }) {
//   const [location, setLocation] = useState("");

//   // Initialize an array outside of the handleSearch function to collect missing locations
//   let missingLocations = [];

//   // const handleSearch = async (location) => {
//   //   try {
//   //     // Replace the Google Geocoding API call with CSV lookup
//   //     const locationData = await getLocationDataFromCSV(location);

//   //     if (locationData) {
//   //       setCenter({ lat: locationData.lat, lng: locationData.lng });
//   //     } else {
//   //       // Instead of logging immediately, add the missing location to the array
//   //       missingLocations.push(location);
//   //       alert(`No results found for location: ${location}`);
//   //     }
//   //   } catch (error) {
//   //     console.error(`Error geocoding location: ${location}`, error);
//   //   }
//   // };

//   // Assume handleSearch is called multiple times in your application logic

//   // After all searches are complete, log the missing locations if any
//   if (missingLocations.length > 0) {
//     console.error(
//       "No results found for the following locations:",
//       missingLocations
//     );
//   }

//   const getLocationDataFromCSV = async (location) => {
//     return new Promise((resolve, reject) => {
//       // Assuming you have the CSV file path as 'path/to/your/file.csv'
//       Papa.parse(
//         "https://cdn.glitch.global/23541ac2-c08b-41ed-b50f-810b03213326/basic_us_city_zip_codes_to_longitude_and_latitude.csv?v=1703796482096",
//         {
//           download: true,
//           header: true,
//           fastMode: true, // Enable fast mode
//           complete: (results) => {
//             // Check if the input is a zip code
//             const isZipCode = /^\d{5}$/.test(location);

//             const entry = results.data.find((row) => {
//               if (isZipCode) {
//                 return row.postalCode === location;
//               } else {
//                 // Assuming you have a column 'city' in your CSV file
//                 return row.city.toLowerCase() === location.toLowerCase();
//               }
//             });

//             if (entry) {
//               resolve({
//                 lat: parseFloat(entry.lat),
//                 lng: parseFloat(entry.lng),
//               });
//             } else {
//               // alert is not working                    alert(`No results found for location: ${location}`);
//               resolve(null);
//             }
//           },
//           error: (error) => {
//             reject(error.message);
//           },
//         }
//       );
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     //       setCenter(initialCenter); // Reset the map's center when submitting the form
//     handleSearch(location);
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <input
//         type="text"
//         placeholder="Enter city or zip code"
//         value={location}
//         onChange={(e) => setLocation(e.target.value)}
//       />
//       <button type="submit">Center Map</button>
//     </form>
//   );
// }

//googleMapsApiKey: "AIzaSyBXFFPvlCuSK2fQHRpMPnPU4bJ9gZbgihE", libraries,

function App() {
  return (
    // if loading, display spinner
    <LoadScript
      id="google-map-script"
      libraries={["geometry"]}
      googleMapsApiKey="AIzaSyBXFFPvlCuSK2fQHRpMPnPU4bJ9gZbgihE"
    >
      <FanMapComponent />
    </LoadScript>
  );
}

export default App;
